@keyframes moveRocket {
  0% {
    transform: translateY(100vh);
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  50% {
    transform: translateY(-50vh);
    opacity: 1;
  }
  70% {
    opacity: 1;
    transform: translateY(-60vh);
  }
  75% {
    transform: translateY(-100vh);
    opacity: 0;
  }
}

@keyframes movementRocket {
  0%,
  100% {
    transform: translateY(-2px);
  }
  50% {
    transform: translateY(2px);
  }
}

.rocket {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  font-size: 10rem;
  opacity: 0;
  z-index: 1000;
  animation: movementRocket 0.2s ease infinite;
}

.rocket::before {
  content: "";
  position: absolute;
  bottom: -18rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1rem;
  height: 20rem;
  background: linear-gradient(#fec83f, transparent);
}
.rocket::after {
  content: "";
  position: absolute;
  bottom: -18rem;
  left: 50%;
  transform: translateX(-50%);
  width: 1rem;
  height: 20rem;
  background: linear-gradient(#fec83f, transparent);
  filter: blur(2rem);
}

.rocket img {
  height: 7rem;
  width: 5rem;
}

.positionSticky {
  animation: moveRocket 10s ease forwards;
}

@media (max-width: 768px) {
  .rocket {
    transform: translateX(-43%);
    left: 43%;
  }
  .rocket::before {
    transform: translateX(-43%);
    /* left: 35%; */
  }
  .rocket::after {
    transform: translateX(-43%);
    /* left: 35%; */
  }
}
