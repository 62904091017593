.container {
  display: flex;
  width: 100%;
  align-items: center;
  overflow-y: auto !important;
  flex-direction: column;
  padding: 10rem 6rem 2rem 6rem;
  background-image: url("../../assets/itadora_4.png");
  background-size: contain;
  font-family: "Libre Baskerville", serif;
}

.textContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  opacity: 0;
  transition: 1s all ease;
  gap: 1rem;
}

.displayText {
  opacity: 1;
  transition: 1s all ease;
}

.arrowLeft {
  position: absolute;
  left: 1rem;
  top: 2rem;
}

.arrowLeft img {
  height: 1.2rem;
}

.mainTitle {
  font-size: 5rem;
  margin: 0;
  text-align: center;
  padding-bottom: 2rem;
}

.title {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  font-size: 3.5rem;
  margin: 0;
  text-align: center;
}

.story {
  font-size: 1.8rem;
  background-color: #f6f6f6;
  width: 100%;
  text-align: justify;
  max-width: 800px;
  padding-top: 2rem;
}

.story br + br {
  margin-bottom: 20px;
  display: block;
  content: "";
}

@media (max-width: 768px) {
  .container {
    padding: 2rem;
  }

  .story {
    font-size: 1.4rem;
  }

  .title {
    font-size: 3rem;
  }
}
