.container {
  width: 30%;
  height: 100vh;
  box-shadow: inset -3px 0px 25px rgba(0, 0, 0, 0.9);
  position: relative;
}

.hideImage {
  visibility: hidden;
  width: 0;
}

.showImage {
  visibility: visible;
  width: 30%;
  animation: fadeOut 0.5s forwards;
  opacity: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  z-index: -2;
}

.timesButton {
  position: absolute;
  top: 1rem;
  right: 1.5rem;
  background-color: transparent;
  color: white;
  border: none;
  font-size: 2.5rem;
  cursor: pointer;
  text-shadow: black 0px 0px 2rem;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.fade-in {
  animation: fadeIn 0.5s forwards;
}

.fade-out {
  animation: fadeOut 0.5s forwards;
}

@media (max-width: 768px) {
  .container {
    display: none;
  }
}
