.scene i {
  position: absolute;
  top: -250px;
  background: rgba(255, 255, 255, 0.5);
  animation: animateStars linear infinite;
}

@keyframes animateStars {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(calc(100vh * 10));
  }
}
