.container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
}

.title {
  font-size: 1.5rem;
  font-weight: 600;
}

.input {
  border: none;
  height: 4rem;
  width: 100%;
  font-size: 1.3rem;
  padding-left: 1rem;
  border-radius: 0.5rem;
}

.input:focus {
  border: 1px solid #2f57b1;
  outline: none;
}

.errorMessage {
  border: 1px solid red;
}

.errorText {
  color: red;
  font-size: 1.3rem;
}
